import $ from "jquery";
import inView from "in-view";
import { handleCountUp } from "./countUp";

export const dataAnimation = () => {
  inView(".js-inview-data-01").once("enter", function (el) {
    el.classList.add("inview-is-visible");

    $(el)
      .find(".js-count-up")
      .each(function () {
        const delayMs = $(this).data("delay"); // +100 of transition-delay value in CSS

        setTimeout(() => {
          handleCountUp($(this));
        }, delayMs);
      });
  });

  inView(".js-inview-data-02").once("enter", function (el) {
    el.classList.add("inview-is-visible");

    $(el)
      .find(".js-count-up")
      .each(function () {
        const delayMs = $(this).data("delay"); // +100 of transition-delay value in CSS

        setTimeout(() => {
          handleCountUp($(this));
        }, delayMs);
      });
  });

  inView(".js-inview-data-03").once("enter", function (el) {
    el.classList.add("inview-is-visible");

    $(el)
      .find(".js-count-up")
      .each(function () {
        const delayMs = $(this).data("delay"); // +100 of transition-delay value in CSS

        setTimeout(() => {
          handleCountUp($(this));
        }, delayMs);
      });
  });
};
