import $ from "jquery";
import Vivus from "vivus";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ ignoreMobileResize: true });

export const frontPageMv = () => {
  const svgEl = $("#top-lines");

  const mediaQueryList = window.matchMedia("(max-width: 768px)");

  let transformVal01;
  let transformVal02;
  let transformVal03;

  const listener = (event) => {
    if (event.matches) {
      // 768px未満
      transformVal01 = "translate(-25%, 10%)";
      transformVal02 = "rotate(-30deg) translate(-30%, -30%)";
      transformVal03 = "rotate(200deg) translate(30%, -28%)";
    } else {
      // 768px以上
      transformVal01 = "translate(-20%, 10%)";
      transformVal02 = "rotate(-45deg) translate(-12%, -40%)";
      transformVal03 = "rotate(200deg) translate(24%, -28%)";
    }
  };
  mediaQueryList.addEventListener("change", listener);

  // 初期化処理
  listener(mediaQueryList);

  const viv = new Vivus("top-lines", {
    duration: 120,
    forceRender: false,
    animTimingFunction: Vivus.EASE,
  });

  function animPattern01() {
    viv.play(1);
    svgEl.css("transform", transformVal01);

    setTimeout(() => {
      viv.play(-1);
    }, 5000);

    setTimeout(animPattern02, 8000);
  }

  function animPattern02() {
    viv.play(1);
    svgEl.css("transform", transformVal02);

    setTimeout(() => {
      viv.play(-1);
    }, 5000);

    setTimeout(animPattern03, 8000);
  }

  function animPattern03() {
    viv.play(1);
    svgEl.css("transform", transformVal03);

    setTimeout(() => {
      viv.play(-1);
    }, 5000);

    setTimeout(animPattern01, 8000);
  }

  // 最初に実行する
  setTimeout(animPattern01, 0);

  gsap.to(svgEl, {
    scrollTrigger: {
      start: "top 20%",
      toggleClass: {
        targets: "#top-lines",
        className: "is-gray",
      },
      trigger: $("#about"),
    },
  });
};
