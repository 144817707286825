export const handleCountUp = (el) => {
  el.text("0");
  const speed = el.attr("data-speed");
  const increment = Number(el.attr("data-increment"));

  const updateCounter = () => {
    const maxNum = el.attr("data-count");
    const displayNum = Number(el.text());

    if (displayNum < maxNum) {
      el.text(`${Math.ceil(displayNum + increment)}`);
      setTimeout(updateCounter, speed);
    } else {
      el.text(maxNum);
    }
  };

  updateCounter();
};
