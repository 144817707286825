import $ from "jquery";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ ignoreMobileResize: true });

export const horizontalScroll = () => {
  const scrollSecs = gsap.utils.toArray(".js-hr-scroll");

  scrollSecs.forEach((scrollSec) => {
    const scrollWrapper = scrollSec.querySelector(".js-hr-scroll-wrapper");
    const scrollContent = scrollSec.querySelector(".js-hr-scroll-content");

    function hrScrollFunc() {
      if ($(window).width() > 768) {
        gsap.to(scrollContent, {
          x: () => -(scrollContent.clientWidth - scrollWrapper.clientWidth),
          ease: "none",
          scrollTrigger: {
            trigger: scrollSec,
            start: "top 5%",
            end: () =>
              `+=${scrollContent.clientWidth - scrollWrapper.clientWidth}`,
            scrub: true,
            pin: true,
            anticipatePin: 1,
            invalidateOnRefresh: true,
          },
        });
      }
    }

    $(window).resize(function () {
      hrScrollFunc();
    });
    hrScrollFunc();
  });
};
