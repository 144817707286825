import Vivus from "vivus";

export const animateLinesInSection = () => {
  const sections = document.querySelectorAll(".js-section-lines");

  const handleInview = function (entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        console.log("entry");

        const lines = entry.target.querySelectorAll(".js-section-line");
        lines.forEach((line, i) => {
          const svg = line.querySelector("svg");

          const vivus = new Vivus(svg.id, {
            duration: 80,
            forceRender: false,
            animTimingFunction: Vivus.EASE,
          });

          // 最初は止める
          vivus.stop();

          // data-section-line-delayがある時は適用
          let delayData;
          if (line.dataset.sectionLineDelay) {
            delayData = line.dataset.sectionLineDelay;
            const delayMs = Number(delayData);
            setTimeout(() => {
              vivus.play(1);
            }, delayMs);
          }
          // ない場合は300msずつ遅らせる
          else {
            setTimeout(() => {
              vivus.play(1);
            }, i * 300);
          }
        });

        observer.unobserve(entry.target);
      }
    });
  };

  const options = {
    rootMargin: "-50px 0px",
  };

  const io = new IntersectionObserver(handleInview, options);
  sections.forEach((el) => {
    io.observe(el);
  });
};
