import $ from "jquery";

export const serviceModal = () => {
  const modal = $("#service-modal");
  const modalCloseBtns = $(".js-service-modal-close");
  const modalItems = $(".js-service-modal-group");
  const modalBtns = $(".js-service-modal-btn");

  modalBtns.on("click", function () {
    const modalId = $(this).data("service-modal-id");
    modal.addClass("is-active");

    const targetModal = $(`[data-service-modal="${modalId}"]`);
    targetModal.addClass("is-active");

    $("body").addClass("is-scroll-disabled");
  });

  modalCloseBtns.on("click", function () {
    $("body").removeClass("is-scroll-disabled");
    modal.removeClass("is-active");
    modalItems.removeClass("is-active");
  });
};
