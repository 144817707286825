import $ from "jquery";

export const anchorScroll = () => {
  const mediaQueryList = window.matchMedia("(max-width: 768px)");
  let headerHeight;

  const listener = (event) => {
    if (event.matches) {
      // 768px未満
      headerHeight = $("#header").innerHeight();
    } else {
      // 768px以上
      headerHeight = $("#header").innerHeight() - 52;
    }
  };
  mediaQueryList.addEventListener("change", listener);

  // 初期化処理
  listener(mediaQueryList);

  var urlHash = location.hash;
  if (urlHash) {
    $("body,html").stop().scrollTop(0);
    setTimeout(function () {
      var target = $(urlHash);
      var position = target.offset().top - headerHeight;
      $("body,html").stop().animate({ scrollTop: position }, 500);

      setTimeout(() => {
        console.log("fire");
        $("body").addClass("is-scrolled");
      }, 550);
    }, 500);
  }

  $('a[href^="#"]').on("click", function () {
    if (!$(this).hasClass("js-no-scroll")) {
      var href = $(this).attr("href");
      var target = $(href);
      var position = target.offset().top - headerHeight;
      $("body,html").stop().animate({ scrollTop: position }, 500);

      setTimeout(() => {
        $("body").addClass("is-scrolled");
      }, 550);
    }
  });
};
