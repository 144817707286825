import $ from "jquery";
import Vivus from "vivus";

export const hamburgerMenu = () => {
  const leftLine = new Vivus("line-menu-left", {
    duration: 80,
    forceRender: false,
    animTimingFunction: Vivus.EASE,
  });

  const rightLine = new Vivus("line-menu-right", {
    duration: 80,
    forceRender: false,
    animTimingFunction: Vivus.EASE,
  });

  $("#hamburger-btn").on("click", function () {
    $("body").toggleClass("is-hamburger-open");
    $(".js-hamburger-menu").toggleClass("is-open");

    if ($("body").hasClass("is-hamburger-open")) {
      // open
      leftLine.play(1);
      rightLine.play(1);
    } else {
      // close
      leftLine.play(-1);
      rightLine.play(-1);
    }
  });
};
