import $ from "jquery";
import { gallerySlider } from "./modules/gallerySlider";
import { managerModal } from "./modules/managerModal";
import { inview } from "./modules/inview";
import { dataAnimation } from "./modules/dataAnimation";
import { faqAccordion } from "./modules/faqAccordion";
import { animateTextOnScroll } from "./modules/animateTextOnScroll";
import { serviceSlider } from "./modules/serviceSlider";
import { toggleHeaderClassOnScroll } from "./modules/toggleHeaderClassOnScroll";
import { frontPageMv } from "./modules/frontPageMv";
import { newsTabs } from "./modules/newsTabs";
import { hamburgerMenu } from "./modules/hamburgerMenu";
import { contactBox } from "./modules/contactBox";
import { pagination } from "./modules/pagination";
import { scrollToTop } from "./modules/scrollToTop";
import { anchorScroll } from "./modules/anchorSCroll";
import { formScrollToError } from "./modules/formScrollToError";
import { subMvLines } from "./modules/subMvLines";
import { lineAnimationOnInview } from "./modules/lineAnimationOnInview";
import { reloadOnResize } from "./modules/reloadOnResize";
import { horizontalScroll } from "./modules/horizonalScroll";
import { serviceModal } from "./modules/serviceModal";
import { inviewMindset } from "./modules/inviewMindset";
import { jobTabs } from "./modules/jobTabs";
import { logoSlider } from "./modules/logoSlider";
import { animateMvLines } from "./modules/animateMvLines";
import { animateLinesInSection } from "./modules/animateLinesInSection";
import { navSub } from "./modules/navSub";
import { autoScroll } from "./modules/autoScroll";

$(function () {
  if ($(".js-front-page").length !== 0) {
    frontPageMv();
  }

  if ($(".js-pagination").length !== 0) {
    pagination();
  }

  if ($(".js-contact-box").length !== 0) {
    contactBox();
  }

  if ($(".js-form").length !== 0) {
    formScrollToError();
  }

  if ($(".js-sub-mv-lines-inview").length !== 0) {
    subMvLines();
  }

  if ($(".js-inview-mindset").length !== 0) {
    inviewMindset();
  }

  if ($(".js-line-anim-inview").length !== 0) {
    lineAnimationOnInview();
  }

  if ($(".js-mv-lines").length !== 0) {
    animateMvLines();
  }

  if ($(".js-section-lines").length !== 0) {
    animateLinesInSection();
  }

  logoSlider();
  reloadOnResize();
  gallerySlider();
  animateTextOnScroll();
  serviceSlider();
  managerModal();
  inview();
  dataAnimation();
  faqAccordion();
  toggleHeaderClassOnScroll();
  newsTabs();
  hamburgerMenu();
  scrollToTop();
  anchorScroll();
  horizontalScroll();
  serviceModal();
  jobTabs();
  animateMvLines();
  navSub();
  autoScroll();
});
