import $ from "jquery";

export const managerModal = () => {
  const modal = $("#manager-modal");
  const modalCloseBtns = $(".js-manager-modal-close");
  const modalItems = $(".js-manager-modal-item");
  const modalBtns = $(".js-manager-modal-btn");

  modalBtns.on("click", function () {
    const modalId = $(this).data("manager-modal-id");
    modal.addClass("is-active");

    const targetModal = $(`[data-manager-modal="${modalId}"]`);
    targetModal.addClass("is-active");

    $("body").addClass("is-scroll-disabled");
  });

  modalCloseBtns.on("click", function () {
    $("body").removeClass("is-scroll-disabled");
    modal.removeClass("is-active");
    modalItems.removeClass("is-active");
  });
};
