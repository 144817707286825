import Swiper from "swiper/bundle";

export const serviceSlider = () => {
  const slider = new Swiper(".js-service-slider", {
    spaceBetween: 12,
    slidesPerView: 1,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1000,
    on: {
      slideChange: function () {
        if (!this.el.classList.contains("not-first-round")) {
          // init時とスライドが1度でも切り替わった時点でクラスを付け替える用
          this.el.classList.add("not-first-round");
        }
      },
    },
  });
};
