import $ from "jquery";

export const navSub = () => {
  const navLink = $(".js-nav-sub-trigger");

  navLink
    .on("mouseover", function () {
      $(this).addClass("is-active");
      $(this).find(".js-nav-sub-parent").addClass("is-active");
      $(this).find(".js-nav-sub").addClass("is-active");
    })
    .on("mouseout", function () {
      $(this).removeClass("is-active");
      $(this).find(".js-nav-sub-parent").removeClass("is-active");
      $(this).find(".js-nav-sub").removeClass("is-active");
    });
};
