import $ from "jquery";

export const formScrollToError = () => {
  // Contact Form 7
  var wpcf7El = document.querySelector(".wpcf7");

  // エラーが発生した時
  wpcf7El.addEventListener(
    "wpcf7invalid",
    function () {
      var speed = 1000; // スクロール速度
      var headerHeight = $("#header").innerHeight(); // ヘッダーの高さを取得
      setTimeout(function () {
        var firstErrorEl = $(".wpcf7-not-valid:first")
          .parent()
          .parent()
          .parent(); // エラーが発生した1番目の要素を取得
        var scrollAmount = firstErrorEl.offset().top - headerHeight; // 要素までのスクロール距離を取得
        $("html, body").animate({ scrollTop: scrollAmount }, speed, "swing"); // 該当箇所までスクロール
      }, 500);
    },
    false
  );
};
