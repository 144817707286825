import $ from "jquery";
import Vivus from "vivus";
import inView from "in-view";

export const subMvLines = () => {
  const leftLine = new Vivus("sub-mv-top-line-left", {
    duration: 80,
    forceRender: false,
    animTimingFunction: Vivus.EASE,
  });

  const rightLine = new Vivus("sub-mv-top-line-right", {
    duration: 80,
    forceRender: false,
    animTimingFunction: Vivus.EASE,
  });

  inView(".js-sub-mv-lines-inview").once("enter", function (el) {
    leftLine.play(1);
    rightLine.play(1);
  });
};
