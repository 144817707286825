import $ from "jquery";
import Vivus from "vivus";
import inView from "in-view";

export const lineAnimationOnInview = () => {
  const lines = document.querySelectorAll(".js-line-anim-inview svg");

  lines.forEach((line) => {
    const vivus = new Vivus(line.id, {
      duration: 80,
      forceRender: false,
      animTimingFunction: Vivus.EASE,
    });

    inView(`${line.id}`).once("enter", function () {
      vivus.play(1);
    });
  });
};
