import $ from "jquery";

export const toggleHeaderClassOnScroll = () => {
  const trigger = $(".js-header-trigger");

  if (trigger.length !== 0) {
    let triggerHeight = trigger.outerHeight() - 80; // 80 = 少し早めにアニメーション開始

    window.addEventListener("scroll", function () {
      const scroll = window.scrollY;
      const body = $("body");

      // MVを超えた時
      if (scroll <= triggerHeight) {
        if (body.hasClass("is-scrolled")) {
          body.removeClass("is-scrolled");
        }
      } else {
        if (!body.hasClass("is-scrolled")) {
          body.addClass("is-scrolled");
        }
      }
    });
  } else {
    window.addEventListener("scroll", function () {
      const scroll = window.scrollY;
      const body = $("body");

      // 100pxスクロールしたとき
      if (scroll <= 100) {
        if (body.hasClass("is-scrolled")) {
          body.removeClass("is-scrolled");
        }
      } else {
        if (!body.hasClass("is-scrolled")) {
          body.addClass("is-scrolled");
        }
      }
    });
  }
};
