import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ ignoreMobileResize: true });

export const autoScroll = () => {
  const scrollEls = gsap.utils.toArray(".js-auto-scroll");

  scrollEls.forEach((scrollEl, i) => {
    let currentScroll = 0;
    let isScrollingDown = true;

    const targetEls = scrollEl.querySelectorAll(".js-auto-scroll-el");

    let tween = gsap
      .to(targetEls, {
        xPercent: -100,
        repeat: -1,
        duration: 60,
        ease: "linear",
      })
      .totalProgress(0.5);

    gsap.set(scrollEl, { xPercent: 0 });

    window.addEventListener("scroll", function () {
      if (window.scrollY > currentScroll) {
        isScrollingDown = true;
      } else {
        isScrollingDown = false;
      }

      gsap.to(tween, {
        timeScale: isScrollingDown ? 1 : -1,
      });

      currentScroll = window.scrollY;
    });
  });
};
