import $ from "jquery";
import inView from "in-view";

export const inview = () => {
  inView(".inview").on("enter", function (el) {
    el.classList.add("inview-is-visible");
  });

  inView(".inview-custom").on("enter", function (el) {
    el.classList.add("inview-is-visible");
  });
};
