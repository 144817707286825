import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ ignoreMobileResize: true });
// ScrollTrigger.normalizeScroll(true);

export const animateTextOnScroll = () => {
  const textEls = gsap.utils.toArray(".js-text-scroll-trigger");

  textEls.forEach((textEl) => {
    const el = textEl.querySelector(".js-text-scroll");

    // gsap.set(el, { x: "0%" });

    gsap.to(el, {
      x: "-50%",
      ease: "power1.in",
      scrollTrigger: {
        trigger: textEl,
        start: "top center",
        end: "+=1000",
        scrub: 4,
      },
    });
  });
};
