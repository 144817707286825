import $ from "jquery";
import Vivus from "vivus";
import inView from "in-view";

export const inviewMindset = () => {
  inView(".js-inview-mindset-01").once("enter", function (el) {
    el.classList.add("inview-is-visible");
    const svgId = el.querySelector("svg").id;

    const leftLine = new Vivus(svgId, {
      duration: 80,
      forceRender: false,
      animTimingFunction: Vivus.EASE,
    });

    setTimeout(() => {
      leftLine.play(1);
    }, 500);
  });

  inView(".js-inview-mindset-02").once("enter", function (el) {
    el.classList.add("inview-is-visible");
    const svgId = el.querySelector("svg").id;

    const leftLine = new Vivus(svgId, {
      duration: 80,
      forceRender: false,
      animTimingFunction: Vivus.EASE,
    });

    setTimeout(() => {
      leftLine.play(1);
    }, 500);
  });

  inView(".js-inview-mindset-03").once("enter", function (el) {
    el.classList.add("inview-is-visible");
    const svgId = el.querySelector("svg").id;

    const leftLine = new Vivus(svgId, {
      duration: 80,
      forceRender: false,
      animTimingFunction: Vivus.EASE,
    });

    setTimeout(() => {
      leftLine.play(1);
    }, 500);
  });

  inView(".js-inview-mindset-04").once("enter", function (el) {
    el.classList.add("inview-is-visible");
    const svgId = el.querySelector("svg").id;

    const leftLine = new Vivus(svgId, {
      duration: 80,
      forceRender: false,
      animTimingFunction: Vivus.EASE,
    });

    setTimeout(() => {
      leftLine.play(1);
    }, 500);
  });

  inView(".js-inview-mindset-05").once("enter", function (el) {
    el.classList.add("inview-is-visible");
    const svgId = el.querySelector("svg").id;

    const leftLine = new Vivus(svgId, {
      duration: 80,
      forceRender: false,
      animTimingFunction: Vivus.EASE,
    });

    setTimeout(() => {
      leftLine.play(1);
    }, 500);
  });
};
