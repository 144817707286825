import $ from "jquery";
import Vivus from "vivus";

export const contactBox = () => {
  const box01 = document.querySelectorAll(".js-contact-box")[0];
  const box02 = document.querySelectorAll(".js-contact-box")[1];
  const area = document.querySelector(".js-contact-box-area");

  const leftLine = new Vivus("contact-left-line", {
    duration: 60,
    forceRender: false,
    animTimingFunction: Vivus.EASE,
    start: "manual",
  });

  const rightLine = new Vivus("business-right-line", {
    duration: 60,
    forceRender: false,
    animTimingFunction: Vivus.EASE,
    start: "manual",
  });

  // leftLine.stop();
  // rightLine.stop();

  box01.addEventListener("mouseenter", (e) => {
    console.log("enter 01");
    e.target.classList.add("is-on");
    if (area.classList.contains("is-business-hovered")) {
      area.classList.remove("is-business-hovered");
    }
    area.classList.add("is-contact-hovered");
    leftLine.play(1);
  });

  box01.addEventListener("mouseleave", (e) => {
    console.log("leave 01");

    e.target.classList.remove("is-on");
    area.classList.remove("is-contact-hovered");
    leftLine.play(-1);
  });

  box02.addEventListener("mouseenter", (e) => {
    console.log("enter 02");
    e.target.classList.add("is-on");
    if (area.classList.contains("is-contact-hovered")) {
      area.classList.remove("is-contact-hovered");
    }
    area.classList.add("is-business-hovered");
    rightLine.play(1);
  });

  box02.addEventListener("mouseleave", (e) => {
    console.log("leave 02");

    e.target.classList.remove("is-on");
    area.classList.remove("is-business-hovered");
    rightLine.play(-1);
  });

  // .eq(0)
  // .mouseenter(function () {
  //   $(this).addClass("is-on");
  //   boxes.eq(1).addClass("is-off");
  //   area.addClass("is-contact-hovered");
  //   if (area.hasClass("is-business-hovered")) {
  //     area.removeClass("is-business-hovered");
  //   }
  //   leftLine.play(1);
  // })
  // .mouseleave(function () {
  //   $(this).removeClass("is-on");
  //   boxes.eq(1).removeClass("is-off");
  //   area.removeClass("is-contact-hovered");
  //   leftLine.play(-1);
  // });

  // boxes
  //   .eq(1)
  //   .mouseenter(function () {
  //     $(this).addClass("is-on");
  //     boxes.eq(0).addClass("is-off");
  //     area.addClass("is-business-hovered");
  //     if (area.hasClass("is-contact-hovered")) {
  //       area.removeClass("is-contact-hovered");
  //     }
  //     rightLine.play(1);
  //   })
  //   .mouseleave(function () {
  //     $(this).removeClass("is-on");
  //     boxes.eq(0).removeClass("is-off");
  //     area.removeClass("is-business-hovered");
  //     rightLine.play(-1);
  //   });
};
