import $ from "jquery";

export const pagination = () => {
  // 3つの点にする
  const extend = $(".pagination .extend");
  extend.html("<span></span>");

  // 10以下の数字の先頭に0をつける
  const first = $(".pagination .first").text("01");

  const page = $(".pagination .page");
  page.each(function (i, el) {
    let num = Number($(el).text());
    let numText = "";

    if (num < 10) {
      numText = 0 + String(num);
    } else {
      numText = String(num);
    }

    $(el).text(numText);
  });

  const current = $(".pagination .current");
  let currentNum = Number($(current).text());
  let currentNumText = "";
  if (currentNum < 10) {
    currentNumText = 0 + String(currentNum);
  } else {
    currentNumText = String(currentNum);
  }
  $(current).text(currentNumText);

  const last = $(".pagination .last");
  let lastNum = Number($(last).text());
  let lastNumText = "";
  if (lastNum < 10) {
    lastNumText = 0 + String(lastNum);
  } else {
    lastNumText = String(lastNum);
  }
  $(last).text(lastNumText);
};
