import $ from "jquery";

export const faqAccordion = () => {
  $(".js-accordion").each(function () {
    const triggers = $(this).find(".js-accordion-trigger");

    $(this).find(".js-accordion-content").hide();
    $(this).find(".js-accordion-content").eq(0).show();
    $(this).find(".js-accordion-trigger").eq(0).addClass("is-open");

    triggers.on("click", function () {
      $(this).toggleClass("is-open");
      $(this).find(".js-accordion-content").slideToggle();
    });
  });
};
