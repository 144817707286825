import Swiper from "swiper/bundle";

export const gallerySlider = () => {
  const slider = new Swiper(".js-gallery-slider", {
    spaceBetween: 8,
    // width: 295,
    loop: true,
    slidesPerView: 1.2,
    loopedSlides: 2,
    centeredSlides: true,
    autoplay: {
      delay: 0,
    },
    speed: 6000,
    allowTouchMove: false,
    breakpoints: {
      769: {
        slidesPerView: 2.5,
      },
    },
  });
};
