import $ from "jquery";
import Vivus from "vivus";
import inView from "in-view";

export const animateMvLines = () => {
  const mv = document.querySelector(".js-mv-lines");
  const lines = document.querySelectorAll(".js-mv-lines .js-mv-line svg");

  inView(".js-mv-lines").once("enter", function () {
    lines.forEach((line, i) => {
      const vivus = new Vivus(line.id, {
        duration: 80,
        forceRender: false,
        animTimingFunction: Vivus.EASE,
      });

      setTimeout(() => {
        vivus.play(1);
      }, i * 300);
    });
  });
};
