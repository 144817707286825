import $ from "jquery";

export const newsTabs = () => {
  const selectors = $(".js-news-tab-selector");
  const tabs = $(".js-news-tab");

  $(".js-news-tab:first-of-type").css("display", "block");

  selectors.on("click", function () {
    const index = $(this).parent().index();
    selectors.removeClass("is-active");

    $(this).addClass("is-active");
    tabs.hide().eq(index).fadeIn(300);
  });
};
